.wide-nav {
    display: float;
    float:right;
    list-style: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    list-style: none;
    margin: 0;
    width: 100%;
    z-index: 3;
    font-size: 3rem;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
  }
  
  #burger {
    display: block; /* show the icon when screen width is 480px or less */
      position: fixed;
      top: 1rem;
      right: 1rem;
      width: 50px;
      height: 30px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      z-index: 4;
  }
  
  #burger span {
      display: block;
      position: absolute;
      height: 7px;
      width: 100%;
      background: #b8b9ed;
      border-radius: 7px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;}
  
  
  #burger span:nth-child(1) {
    top: 0px;
  }
  
  #burger span:nth-child(2),#burger span:nth-child(3) {
    top: 13px;
  }
  
  #burger span:nth-child(4) {
    top: 26px;
  }
  
  #burger.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
  #burger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #burger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #burger.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
  #sidebar {
    transform: translateX(100%);
    min-width: 10rem;
    width: 20vw;
    height: 100vh;
    background-color: rgb(51, 51, 51);
    color: #B8B9ED;
    float: right;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 5rem;
    transition: transform 0.5s ease-out;
    z-index: 3;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  #sidebar.side-open {
    transform: translateX(0%);  
  }
  
  .side-menu {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #overlay {
    transition: opacity 0.2s ease-in-out;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .overlay-hidden {
    display: none;
  }